
   
   .wholepage{
    height: 100vh;
    /* margin-bottom: 40px; */
    background-color: #f7f7f7;
   }
   .Registration {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130vh;
    /* margin-bottom: 40px; */
    background-color: #f7f7f7;
  }
  
  .mainpage-registration {
    width:80%;
    /* z-index: 1; */
    height: 100vh;
    /* margin-top: 20px; */
    /* width: 800px; */
    /* padding-bottom: 50px; */
    background-color: #fff;
    border-radius: 8px;
background: transparent;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  .card {
   
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    width: 800px;
    justify-content: center;
    /* height: 800px; */
    align-items: center;
    padding-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  }
  
  .card-content {
    flex: 1;
    /* padding: 20px; */
  }
  
  .left-content {
    background-color:#FFA438;
    display: flex;
    justify-content: center;
margin: 20px;
    align-items: center;
  }
  
  .left-content img {
    max-width: 100%;
    height: 100%;
    border-radius:200px ;
  }
  
  .right-content {
    width: 50%;
    margin-top: 20px;
    /* padding: 0 20px; */
  }
  
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  form{
    /* height: 00px; */
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"],select,textarea {
    width: 80%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button[type="submit"] {
    width: 90%;
    margin-top: 20px;
    padding: 10px;
    background-color: #AD9551;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #FFA438;
  }
  
  p {
    margin-top: 10px;
    text-align: center;
  }
  
  p a {
    color: #007bff;
    text-decoration: none;
  }
  
  p a:hover {
    text-decoration: underline;
  }
  
  .logo {
    position: absolute;
    top: 20%;
    right: 1px;
    width: 100px; /* Adjust size as needed */
    height: 50%; /* Maintain aspect ratio */
    width: 30%;
    border-radius:200px ;
  }
select {
  width: 88%;
  border-radius: 6px;
  height: 40px;
  appearance: none; /* Remove default arrow on select */
  /* background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" /><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V5zm13 11V6H4v10h12z" clip-rule="evenodd" /></svg>'); Custom arrow icon */
  /* background-repeat: no-repeat; */
  /* background-position: right 10px center; Position arrow icon */
  /* background-size: 12px; Size of arrow icon */
}

textarea {
  height: 100px; /* Adjust height for address field */
}
@media (max-width: 768px) {
  .Registration{
    width: 100%;
    height: 150vh;
  }
  .mainpage-registration {
    width: 70%;
    height: 100%;
    /* padding-bottom: 30px; */
    background-color: #fff;
    border-radius: 8px;
    background: transparent;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  .right-content p{
    /* margin-top: 40px; */
  }
  card-container{
    margin: 20px;

  }

  .card {
    width: 100%;
    flex-direction: column;
    height: 950px;
    margin: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  }

  .right-content {
    height: 900px;
    width: 100%;
    /* margin-bottom: 20px; */
    /* padding: 0 10px; */
  }
.form-group{
  /* align-items: center; */
  display: flex;
  justify-content: left;
/* justify-content: center; */
/* height: 550px;
 */
}

form{
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* margin-top: 30px; */
  /* align-items: center; */
  height: 550px;
}
  input[type="text"],
  input[type="email"],
  input[type="password"],
 #address,option,label {
    /* align-items: center; */
    width: 80%;
    border-radius: 5px;
    /* border: 1px solid #addressccc; */
    height: 15px;
  }
select #gender{
  padding: 10px;
}
  button[type="submit"] {
    /* width: 100%; */
    width: 50%;
    align-items: center;
    text-align: center; 
    justify-content: center;
    margin-top: 20px;
    /* padding: 10px; */
  }
button{
  align-items: center;
  display: flex;
  /* width: 67%; */
  justify-content: center;
}

.logo{
    position: relative;
    top: unset;
    right: unset;
    width: 100%; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    border-radius: 0;
    margin-bottom: 20px;
}
}

