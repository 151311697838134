
/* styles.css */
@font-face {
  font-family: 'sedan';
  src: url('../src/font/IBM_Plex_Sans/IBMPlexSans-Light.ttf') format('truetype');
  
}

body {
  font-family: 'sedan', sans-serif;
  /* Additional styles */
}

