/* Login.css */

.Login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .mainpage-login {
    width: 500px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .mainpage-login h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="email"],
  input[type="password"],
  button[type="submit"] {
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
  
  button[type="submit"] {
    background-color: #FFA438;
    color: #fff;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #fc8a00;
  }
  @media (max-width: 768px) {
    .mainpage-login{
      width: 80;
    }
    .Login{
      width: 100%;
      height: 100ch;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }